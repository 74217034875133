import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, X } from "lucide-react";
import HeaderLogo from "../../assets/RajarataLogo.svg";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const isActive = (path) => location.pathname === path;

  return (
    <header
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
        isScrolled ? "bg-white shadow-lg py-2" : "bg-white py-4"
      }`}
    >
      <div className="max-w-7xl mx-auto px-6">
        <nav className="flex items-center justify-between">
          {/* Logo */}
          <Link to="/" className="flex-shrink-0">
            <img
              src={HeaderLogo}
              alt="Logo"
              className="h-12 w-auto brightness-0"
            />
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            <Link
              to="/"
              className={`text-[#215E5C] hover:text-[#3a8f8c] transition-colors duration-300 relative group ${
                isActive("/") ? "font-semibold" : ""
              }`}
            >
              <span className="relative z-10">Home</span>
              <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-[#3a8f8c] group-hover:w-full transition-all duration-300"></span>
            </Link>
            <Link
              to="/about-us"
              className={`text-[#215E5C] hover:text-[#3a8f8c] transition-colors duration-300 relative group ${
                isActive("/about") ? "font-semibold" : ""
              }`}
            >
              <span className="relative z-10">About Us</span>
              <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-[#3a8f8c] group-hover:w-full transition-all duration-300"></span>
            </Link>
            <Link
              to="/locations"
              className={`text-[#215E5C] hover:text-[#3a8f8c] transition-colors duration-300 relative group ${
                isActive("/locations") ? "font-semibold" : ""
              }`}
            >
              <span className="relative z-10">Locations</span>
              <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-[#3a8f8c] group-hover:w-full transition-all duration-300"></span>
            </Link>
            <Link
              to="/contact"
              className={`text-[#215E5C] hover:text-[#3a8f8c] transition-colors duration-300 relative group ${
                isActive("/contact") ? "font-semibold" : ""
              }`}
            >
              <span className="relative z-10">Contact Us</span>
              <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-[#3a8f8c] group-hover:w-full transition-all duration-300"></span>
            </Link>
            <Link
              to="/trips-view"
              className="px-6 py-2 bg-[#215E5C] text-white rounded-lg font-medium hover:bg-[#3a8f8c] transition-colors duration-300"
            >
              Book a Trip
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden p-2 text-[#215E5C] hover:text-[#3a8f8c]"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </nav>
      </div>

      {/* Mobile Navigation */}
      {isMobileMenuOpen && (
        <div className="md:hidden fixed inset-0 bg-[#215E5C] text-white z-40">
          <button
            className="absolute top-5 right-5 p-2 text-white hover:text-[#3a8f8c] transition-colors duration-300"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            <X size={28} />
          </button>
          <div className="flex flex-col items-center space-y-8 pt-20 px-8">
            <Link
              to="/"
              className="text-lg hover:text-[#3a8f8c] transition-colors duration-300"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Home
            </Link>
            <Link
              to="/about"
              className="text-lg hover:text-[#3a8f8c] transition-colors duration-300"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              About Us
            </Link>
            <Link
              to="/locations"
              className="text-lg hover:text-[#3a8f8c] transition-colors duration-300"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Locations
            </Link>
            <Link
              to="/contact"
              className="text-lg hover:text-[#3a8f8c] transition-colors duration-300"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Contact Us
            </Link>
            <Link
              to="/book"
              className="w-full text-center px-6 py-2 bg-[#3a8f8c] rounded-lg font-medium transition-colors duration-300"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Book a Trip
            </Link>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
