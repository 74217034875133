import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/firebase';
import { collection, addDoc, getDocs, updateDoc, doc, deleteDoc } from 'firebase/firestore';

const Locations = () => {
  const [locationData, setLocationData] = useState({
    name: '',
    description: '',
    distanceColombo: '',
    timeColombo: '',
    distanceKatunayaka: '',
    timeKatunayaka: '',
    town: '',
  });
  const [locationBannerImage, setLocationBannerImage] = useState(null);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [editingId, setEditingId] = useState(null);
  const [updatingLocationId, setUpdatingLocationId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [locationToDelete, setLocationToDelete] = useState(null);


  const fetchLocations = async () => {
    setFetching(true);
    const locationsCollection = collection(db, 'locations');
    const locationSnapshot = await getDocs(locationsCollection);
    const locationList = locationSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setLocations(locationList);
    setFetching(false);
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocationData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleImageUpload = (event) => {
    setLocationBannerImage(event.target.files[0]);
  };

  const uploadToCloudinary = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "unsigned_upload"); 

    const response = await fetch(
      `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/upload`,
      {
        method: "POST",
        body: formData,
      }
    );

    const data = await response.json();
    return data.secure_url;
  };

  const handleAddLocation = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let locationBannerUrl = '';
      if (locationBannerImage) {
        locationBannerUrl = await uploadToCloudinary(locationBannerImage);
      }

      if (editingId) {
        const locationRef = doc(db, 'locations', editingId);
        await updateDoc(locationRef, {
          ...locationData,
          ...(locationBannerUrl && { locationBanner: locationBannerUrl })
        });
        alert('Location updated successfully');
      } else {
        await addDoc(collection(db, 'locations'), {
          ...locationData,
          locationBanner: locationBannerUrl
        });
        alert('Location added successfully');
      }
      
      resetForm();
      fetchLocations();
    } catch (error) {
      console.error('Error saving location:', error);
      alert('Failed to save location');
    } finally {
      setLoading(false);
    }
  };

  const handleEditLocation = (location) => {
    setLocationData({
      name: location.name,
      description: location.description || '',
      distanceColombo: location.distanceColombo || '',
      timeColombo: location.timeColombo || '',
      distanceKatunayaka: location.distanceKatunayaka || '',
      timeKatunayaka: location.timeKatunayaka || '',
      town: location.town || ''
    });
    setEditingId(location.id);
    setUpdatingLocationId(location.id);
  };

  const resetForm = () => {
    setLocationData({
      name: '',
      description: '',
      distanceColombo: '',
      timeColombo: '',
      distanceKatunayaka: '',
      timeKatunayaka: '',
      town: ''
    });
    setLocationBannerImage(null);
    setEditingId(null);
    setUpdatingLocationId(null);
  };

  const handleDeleteLocation = async () => {
    if (locationToDelete) {
      const locationRef = doc(db, 'locations', locationToDelete);
      await deleteDoc(locationRef);
      alert('Location deleted successfully');
      fetchLocations();
    }
    setShowDeleteModal(false);
  };

  return (
    <div className="p-6 rounded-lg shadow-md" style={{ backgroundColor: '#1A4746', color: '#EAF4F4' }}>
      <h2 className="text-2xl font-bold mb-4">Manage Locations</h2>
      <p>Here you can view, add, edit, and delete locations.</p>

      <form onSubmit={handleAddLocation} className="mt-4 mb-6 space-y-4">
        {/* Location Name */}
        <div className="space-y-2">
          <label className="block text-lg">Location Name</label>
          <input
            type="text"
            name="name"
            value={locationData.name}
            onChange={handleInputChange}
            className="w-full p-3 border rounded bg-[#215E5C] text-[#EAF4F4] border-[#D9E7E6] focus:outline-none focus:ring-2 focus:ring-[#F4A261]"
            required
          />
        </div>

        {/* Description */}
        <div className="space-y-2">
          <label className="block text-lg">Description</label>
          <textarea
            name="description"
            value={locationData.description}
            onChange={handleInputChange}
            className="w-full p-3 border rounded bg-[#215E5C] text-[#EAF4F4] border-[#D9E7E6] focus:outline-none focus:ring-2 focus:ring-[#F4A261]"
            rows="4"
          />
        </div>

        {/* Distance and Time Fields */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="space-y-2">
            <label className="block text-lg">Distance from Colombo</label>
            <input
              type="text"
              name="distanceColombo"
              value={locationData.distanceColombo}
              onChange={handleInputChange}
              className="w-full p-3 border rounded bg-[#215E5C] text-[#EAF4F4] border-[#D9E7E6] focus:outline-none focus:ring-2 focus:ring-[#F4A261]"
            />
          </div>
          <div className="space-y-2">
            <label className="block text-lg">Time from Colombo</label>
            <input
              type="text"
              name="timeColombo"
              value={locationData.timeColombo}
              onChange={handleInputChange}
              className="w-full p-3 border rounded bg-[#215E5C] text-[#EAF4F4] border-[#D9E7E6] focus:outline-none focus:ring-2 focus:ring-[#F4A261]"
            />
          </div>
          <div className="space-y-2">
            <label className="block text-lg">Distance from Katunayaka</label>
            <input
              type="text"
              name="distanceKatunayaka"
              value={locationData.distanceKatunayaka}
              onChange={handleInputChange}
              className="w-full p-3 border rounded bg-[#215E5C] text-[#EAF4F4] border-[#D9E7E6] focus:outline-none focus:ring-2 focus:ring-[#F4A261]"
            />
          </div>
          <div className="space-y-2">
            <label className="block text-lg">Time from Katunayaka</label>
            <input
              type="text"
              name="timeKatunayaka"
              value={locationData.timeKatunayaka}
              onChange={handleInputChange}
              className="w-full p-3 border rounded bg-[#215E5C] text-[#EAF4F4] border-[#D9E7E6] focus:outline-none focus:ring-2 focus:ring-[#F4A261]"
            />
          </div>
        </div>

        {/* Town */}
        <div className="space-y-2">
          <label className="block text-lg">Town</label>
          <input
            type="text"
            name="town"
            value={locationData.town}
            onChange={handleInputChange}
            className="w-full p-3 border rounded bg-[#215E5C] text-[#EAF4F4] border-[#D9E7E6] focus:outline-none focus:ring-2 focus:ring-[#F4A261]"
          />
        </div>

     
        <div className="space-y-2">
          <label className="block text-lg">Location Banner Image</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="w-full p-3 border rounded bg-[#215E5C] text-[#EAF4F4] border-[#D9E7E6] focus:outline-none focus:ring-2 focus:ring-[#F4A261]"
          />
        </div>

        <div className="flex justify-between items-center">
          <button
            type="submit"
            className={`py-2 px-4 rounded-md font-medium ${loading ? 'bg-gray-400' : 'bg-[#F4A261]'} ${loading ? 'cursor-not-allowed' : 'cursor-pointer'}`}
            disabled={loading}
          >
            {loading ? 'Saving...' : editingId ? 'Update Location' : 'Add New Location'}
          </button>

          {editingId && (
            <button
              type="button"
              className="ml-2 py-2 px-4 rounded-md bg-red-500 text-white"
              onClick={resetForm}
            >
              Cancel
            </button>
          )}
        </div>
      </form>

      <h3 className="text-xl font-bold mb-2">Uploaded Locations</h3>

      {fetching ? (
        <div className="flex justify-center items-center">
          <div className="flex flex-col items-center">
            <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-[#F4A261] mb-2"></div>
            <p>Loading data...</p>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {locations.map(location => (
            <div key={location.id} className="bg-[#215E5C] p-4 rounded-lg shadow-lg">
              {location.locationBanner && (
                <img 
                  src={location.locationBanner} 
                  alt={location.name}
                  className="w-full h-40 object-cover rounded-md mb-4"
                />
              )}
              <div className="flex justify-between items-start">
                <div>
                  <h4 className="text-lg font-bold">{location.name}</h4>
                  <p className="text-sm mt-1">{location.town}</p>
                  <p className="text-sm mt-1">Distance from Colombo: {location.distanceColombo}</p>
                  <p className="text-sm">Time from Colombo: {location.timeColombo}</p>
                </div>
                <div className="flex space-x-2">
                  <button 
                    className="text-blue-500 hover:underline" 
                    onClick={() => handleEditLocation(location)}
                  >
                    Edit
                  </button>
                  <button 
                    className="text-red-500 hover:underline" 
                    onClick={() => {
                      setLocationToDelete(location.id);
                      setShowDeleteModal(true);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
              {updatingLocationId === location.id && (
                <div className="text-yellow-300 mt-2 animate-pulse">Updating...</div>
              )}
            </div>
          ))}
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg text-center">
            <h3 className="text-lg font-bold mb-4 text-gray-800">Confirm Deletion</h3>
            <p className="text-gray-600">Are you sure you want to delete this location?</p>
            <div className="mt-4 flex justify-around">
              <button className="py-2 px-4 bg-red-500 text-white rounded" onClick={handleDeleteLocation}>
                Yes, Delete
              </button>
              <button className="py-2 px-4 bg-gray-300 rounded" onClick={() => setShowDeleteModal(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Locations;