import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const AdminLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [honeypot, setHoneypot] = useState("");
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();

    // Honeypot check
    if (honeypot) {
      alert("Bot detected");
      return;
    }

    // login check
    if (username === "admin" && password === "password123") {
      navigate("/admin-panel");
    } else {
      alert("Incorrect username or password");
    }
  };

  return (
    <div
      className="flex justify-center items-center min-h-screen"
      style={{ backgroundColor: "#D9E7E6" }}
    >
      <form
        onSubmit={handleLogin}
        className="p-8 rounded-lg shadow-lg w-full max-w-md"
        style={{ backgroundColor: "#1A4746" }}
      >
        <h2 className="text-3xl font-bold mb-6" style={{ color: "#EAF4F4" }}>
          Admin Login
        </h2>

        {/* Honeypot field */}
        <input
          type="text"
          value={honeypot}
          onChange={(e) => setHoneypot(e.target.value)}
          className="hidden"
          tabIndex="-1"
          autoComplete="off"
          placeholder="Leave this field empty"
        />

        <label className="block mb-4">
          <span className="text-lg" style={{ color: "#EAF4F4" }}>
            Username
          </span>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="w-full p-3 mt-2 border rounded"
            style={{
              backgroundColor: "#215E5C",
              color: "#EAF4F4",
              borderColor: "#D9E7E6",
            }}
            required
          />
        </label>

        <label className="block mb-6">
          <span className="text-lg" style={{ color: "#EAF4F4" }}>
            Password
          </span>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-3 mt-2 border rounded"
            style={{
              backgroundColor: "#215E5C",
              color: "#EAF4F4",
              borderColor: "#D9E7E6",
            }}
            required
          />
        </label>

        <button
          type="submit"
          className="w-full py-2 px-4 rounded-md font-medium"
          style={{ backgroundColor: "#F4A261", color: "#215E5C" }}
        >
          Login
        </button>
      </form>
    </div>
  );
};

export default AdminLogin;
