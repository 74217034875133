// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDaEk7i-MQhZzCaszsg3ZjicSN_sqoDHwI",
  authDomain: "rajarata-tours.firebaseapp.com",
  projectId: "rajarata-tours",
  storageBucket: "rajarata-tours.firebasestorage.app",
  messagingSenderId: "839162077683",
  appId: "1:839162077683:web:7e7a4c42a65811d8777286",
  measurementId: "G-B50DW4V14S"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };