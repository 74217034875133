import React, { lazy, Suspense, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Direct imports
import bgImg from "../../../assets/beach-bg.webp";
import logo from "../../../assets/logo.svg";
import text from "../../../assets/text_SL.svg";
import exploreButton from "../../../assets/exploreButton.svg";
import info from "../../../assets/s.svg";

// Lazy load components
const ImageCarousel = lazy(() =>
  import("./Sections-LoadingPage/ImageCarousel")
);
const ExploreSection = lazy(() =>
  import("./Sections-LoadingPage/ExploreSection")
);
const DiversityLine = lazy(() =>
  import("./Sections-LoadingPage/DiversityLine")
);
const TravelPage = lazy(() => import("./Sections-LoadingPage/TravelPage"));
const IconStrip = lazy(() => import("./Sections-LoadingPage/IconStrip"));
const ContactUs = lazy(() => import("./Sections-LoadingPage/ContactUs"));
const FooterSection = lazy(() =>
  import("./Sections-LoadingPage/FooterSection")
);

// Loading spinner component
const LoadingSpinner = ({ size = 8, color = "#215E5C" }) => (
  <div className="flex justify-center items-center p-8">
    <div
      className={`animate-spin rounded-full h-${size} w-${size} border-b-2`}
      style={{ borderColor: color }}
    />
  </div>
);

// Navigation component
const Navigation = ({ isMenuOpen, setIsMenuOpen }) => (
  <div
    className={`${
      isMenuOpen ? "flex" : "hidden"
    } md:flex flex-col md:flex-row items-center bg-white/80 hover:bg-white transition-colors duration-300 rounded-2xl md:rounded-full px-6 md:px-12 py-4 mt-4 w-full md:w-auto`}
  >
    <ul className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-12 w-full md:w-auto">
      {["Home", "About us", "Locations", "Contact us"].map((item) => (
        <li key={item}>
          <a
            href={
              item === "Home" ? "/" : `/${item.toLowerCase().replace(" ", "-")}`
            }
            className="text-[#215E5C] hover:text-[#3a8f8c] transition-colors duration-300 relative group"
          >
            <span className="relative z-10">{item}</span>
            <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-[#3a8f8c] group-hover:w-full transition-all duration-300"></span>
          </a>
        </li>
      ))}
    </ul>
    <Link to="/trips-view" className="mt-4 md:mt-0 md:ml-10">
      <button className="bg-[#215E5C] text-white px-8 py-2 rounded-full font-medium hover:bg-[#3a8f8c] transition-colors duration-300 w-full md:w-auto">
        Book Trip
      </button>
    </Link>
  </div>
);

const SriLankaLandingPage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [bgLoaded, setBgLoaded] = useState(false);

  useEffect(() => {
    // Preload background image
    const img = new Image();
    img.src = bgImg;
    img.onload = () => setBgLoaded(true);
  }, []);

  return (
    <main className="relative w-full">
      {/* Hero Section */}
      <section className="relative min-h-screen bg-[#215E5C]">
        {/* Background Image */}
        <div
          className={`absolute inset-0 w-full h-full bg-cover bg-center transition-opacity duration-500 ${
            bgLoaded ? "opacity-100" : "opacity-0"
          }`}
          style={{ backgroundImage: `url(${bgImg})` }}
        />

        <div className="relative max-w-7xl mx-auto px-4 md:px-6">
          {/* Top bar*/}
          <div className="relative flex justify-between items-center pt-4 ">
            {/* Logo */}
            <div className="w-24 md:w-32 h-24 md:h-32 flex items-center -mt-4">
              <img
                src={logo}
                alt="Rajarata"
                className="w-full h-full object-contain"
                width="128"
                height="128"
                loading="eager"
              />
            </div>

            {/* Mobile Menu*/}
            <button
              className="md:hidden absolute right-4 top-8 text-white"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              aria-label="Toggle menu"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16m-16 6h16"
                />
              </svg>
            </button>

            <div className="hidden md:block -mt-8 -mr-4">
              <Navigation
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
              />
            </div>
          </div>

          {/* Mobile Navigation */}
          <div className="md:hidden">
            {isMenuOpen && (
              <Navigation
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
              />
            )}
          </div>

          {/* Main Content */}
          <div className="flex flex-col md:flex-row justify-between items-center md:items-start mt-96 md:mt-36 pb-12">
            {/* Left Content */}
            <div className="max-w-full md:max-w-2xl px-4 md:px-0 text-center md:text-left">
              <h1 className="font-montserrat text-white">
                <div className="text-4xl md:text-7xl font-bold mb-2">
                  Explore
                </div>
                <div className="md:text-8xl font-extrabold mb-10">
                  <img
                    src={text}
                    alt="Sri Lanka"
                    className="h-5xl mx-auto md:mx-0"
                    loading="eager"
                  />
                </div>
              </h1>

              <div className="-mt-8 md:-mt-16">
                <p className="text-white/90 text-base md:text-lg max-w-xl animate-fadeIn">
                  The 5th best country to visit in your lifetime, according to
                  CEOWORLD Magazine's prestigious rankings 2024
                </p>
                <div className="flex flex-col md:flex-row items-center gap-4 md:gap-8 mt-6 animate-fadeIn">
                  <div className="relative">
                    <Link to="/trips-view">
                      <img
                        src={exploreButton}
                        alt="Explore"
                        className="transform scale-75 md:scale-100 hover:scale-110 transition-transform duration-300"
                        width="200"
                        height="60"
                        loading="eager"
                      />
                    </Link>
                  </div>
                  <div className="relative">
                    <div className="flex flex-col items-center">
                      <Link to="https://eta.gov.lk/slvisa/">
                        <button className="flex items-center hover:scale-110 transition-transform duration-300">
                          <span className="text-white underline">E-Visa</span>
                          <img
                            src={info}
                            alt="E-Visa info"
                            className="ml-1"
                            width="20"
                            height="20"
                            loading="eager"
                          />
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Image Carousel */}
            <div className="relative w-full md:w-auto mt-16 md:mt-24 hidden md:block">
              <Suspense fallback={<LoadingSpinner />}>
                <ImageCarousel />
              </Suspense>
            </div>
          </div>
        </div>
      </section>

      {/* Lazy loaded sections with loading spinner fallback */}
      <Suspense fallback={<LoadingSpinner />}>
        <DiversityLine />
        <ExploreSection />
        <TravelPage />
        <IconStrip />
        <ContactUs />
        <FooterSection />
      </Suspense>
    </main>
  );
};

export default SriLankaLandingPage;
