import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import { useNavigate } from "react-router-dom";
import { Search, X, MapPin, Navigation, Plus, Check } from "lucide-react";
import Header from "../Header";

const LocationView = () => {
  const navigate = useNavigate();
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isClosing, setIsClosing] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);

  useEffect(() => {
    async function fetchLocations() {
      const locationCollection = collection(db, "locations");
      const locationSnapshot = await getDocs(locationCollection);
      const locationList = locationSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLocations(locationList);
      setFilteredLocations(locationList);
      setLoading(false);
    }

    fetchLocations();
  }, []);

  useEffect(() => {
    if (search.trim() === "") {
      setFilteredLocations(locations);
    } else {
      setFilteredLocations(
        locations.filter(
          (loc) =>
            loc.name && loc.name.toLowerCase().includes(search.toLowerCase())
        )
      );
    }
  }, [search, locations]);
  const style = `
  .custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #427D7B #EAF4F4;
  }

  .custom-scrollbar::-webkit-scrollbar {
    width: 6px;
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    background: #EAF4F4;
    border-radius: 8px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #427D7B;
    border-radius: 8px;
    border: 2px solid #EAF4F4;
  }
`;

const styleSheet = document.createElement("style");
styleSheet.innerText = style;
document.head.appendChild(styleSheet);

  const toggleLocationSelection = (location) => {
    setSelectedLocations((prev) => {
      const isSelected = prev.some((loc) => loc.id === location.id);
      if (isSelected) {
        return prev.filter((loc) => loc.id !== location.id);
      } else {
        return [...prev, location];
      }
    });
  };

  const closePopup = () => {
    setIsClosing(true);
    setTimeout(() => {
      setSelectedLocation(null);
      setIsClosing(false);
    }, 200);
  };

  const handlePlanVisit = () => {
    const locationNames = selectedLocations.map((loc) => loc.name).join(",");
    navigate(`/location-form?locations=${encodeURIComponent(locationNames)}`);
  };

  if (loading) {
    return (
      <>
        <Header />
        <div className="min-h-screen bg-[#EAF4F4] flex items-center justify-center">
          <div className="text-center">
            <div className="relative w-24 h-24 mx-auto mb-8">
              <div className="absolute inset-0 border-4 border-[#215E5C]/20 rounded-full"></div>
              <div className="absolute inset-0 border-4 border-t-[#215E5C] rounded-full animate-spin"></div>
            </div>
            <p className="text-[#215E5C] text-lg animate-pulse">
              Loading amazing destinations...
            </p>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      <main className="min-h-screen bg-gradient-to-b from-[#1A4746] to-[#2A5756] pb-24">
        {/* Hero Section */}
        <div className="relative">
          <div className="absolute inset-0 bg-[#1A4746] opacity-50 mix-blend-overlay"></div>
          <div className="relative w-full pt-36 pb-24">
            <div className="max-w-7xl mx-auto px-6">
              <div className="relative">
                <div className="absolute -top-20 left-1/2 transform -translate-x-1/2 w-96 h-96 bg-[#F4A261] rounded-full blur-[128px] opacity-20"></div>
                <h1 className="text-5xl md:text-6xl font-bold mb-6 text-white text-center leading-tight relative z-10">
                  Explore the Beauty of{" "}
                  <span className="text-[#F4A261]">Sri Lanka</span>
                </h1>
                <p className="text-xl text-center text-[#D9E7E6] max-w-2xl mx-auto relative z-10">
                  Discover breathtaking destinations across the pearl of the
                  Indian Ocean
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Search Section */}
        <div className="px-6 -mt-8">
          <div className="max-w-7xl mx-auto">
            <div className="bg-white rounded-2xl shadow-xl p-6">
              <div className="relative">
                <Search
                  className="absolute left-4 top-1/2 transform -translate-y-1/2 text-[#9BBAB9]"
                  size={20}
                />
                <input
                  type="text"
                  placeholder="Search your dream destination..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="w-full pl-12 pr-4 py-4 rounded-xl border-2 border-[#D9E7E6] bg-white text-[#1A4746] placeholder:text-[#9BBAB9] focus:outline-none focus:border-[#427D7B] focus:ring-2 focus:ring-[#427D7B] focus:ring-opacity-20 transition-all duration-300"
                />
              </div>

              {/* Selected Locations Bar */}
              {selectedLocations.length > 0 && (
                <div className="mt-4 p-4 bg-[#EAF4F4] rounded-xl">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                      <span className="text-[#1A4746] font-medium">
                        {selectedLocations.length} location
                        {selectedLocations.length !== 1 ? "s" : ""} selected
                      </span>
                      <div className="flex -space-x-2">
                        {selectedLocations.slice(0, 3).map((location) => (
                          <div
                            key={location.id}
                            className="w-8 h-8 rounded-full border-2 border-white overflow-hidden relative group cursor-pointer"
                            onClick={() => toggleLocationSelection(location)}
                          >
                            <img
                              src={location.locationBanner}
                              alt={location.name}
                              className="w-full h-full object-cover"
                            />
                            <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                              <X className="w-4 h-4 text-white" />
                            </div>
                          </div>
                        ))}
                        {selectedLocations.length > 3 && (
                          <div className="w-8 h-8 rounded-full border-2 border-white bg-[#1A4746] flex items-center justify-center text-white text-sm">
                            +{selectedLocations.length - 3}
                          </div>
                        )}
                      </div>
                    </div>
                    <button
                      onClick={handlePlanVisit}
                      className="px-6 py-2 bg-[#F4A261] text-white rounded-lg hover:bg-[#F4B583] transition-all duration-300"
                    >
                      Plan Visit
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Locations Grid */}
        <div className="px-6 py-12">
          <div className="max-w-7xl mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            {filteredLocations.map((location) => (
              <div
                key={location.id}
                className={`group bg-white rounded-2xl shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300 overflow-hidden ${
                  selectedLocations.some((loc) => loc.id === location.id)
                    ? "ring-2 ring-[#F4A261]"
                    : ""
                }`}
              >
                <div className="relative overflow-hidden">
                  <img
                    src={location.locationBanner}
                    alt={location.name}
                    className="w-full h-64 object-cover transform group-hover:scale-105 transition-transform duration-500"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-transparent to-transparent"></div>

                  {/* Selection Toggle Button */}
                  <div className="absolute top-4 right-4">
                    {selectedLocations.some((loc) => loc.id === location.id) ? (
                      <div
                        onClick={() => toggleLocationSelection(location)}
                        className="w-10 h-10 rounded-full bg-[#F4A261] shadow-lg flex items-center justify-center transform hover:scale-105 transition-all duration-300 cursor-pointer"
                      >
                        <Check className="w-6 h-6 text-white" />
                      </div>
                    ) : (
                      <div
                        onClick={() => toggleLocationSelection(location)}
                        className="w-10 h-10 rounded-full bg-white shadow-lg flex items-center justify-center transform hover:scale-105 transition-all duration-300 hover:bg-[#EAF4F4] cursor-pointer"
                      >
                        <Plus
                          className="w-6 h-6 text-[#1A4746] hover:text-[#F4A261]"
                          strokeWidth={2.5}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="p-6">
                  <h3 className="text-xl font-semibold text-[#1A4746] mb-2">
                    {location.name}
                  </h3>
                  <div className="flex items-center text-[#427D7B] mb-4">
                    <MapPin className="w-4 h-4 mr-2" />
                    <p className="text-sm">{location.town}, Sri Lanka</p>
                  </div>
                  <button
                    className="w-full px-6 py-3 bg-[#F4A261] text-white rounded-xl hover:bg-[#F4B583] transition-all duration-300 transform hover:scale-[1.02] active:scale-[0.98]"
                    onClick={() => setSelectedLocation(location)}
                  >
                    View Details
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Location Details Modal */}
        {selectedLocation && (
          <div
            className="fixed inset-0 flex items-center justify-center z-50"
            onClick={closePopup}
          >
            <div
              className={`fixed inset-0 bg-black transition-opacity duration-200 ${
                isClosing ? "opacity-0" : "opacity-50"
              }`}
            />
            <div
              className={`relative max-w-2xl w-full mx-4 bg-white rounded-2xl shadow-2xl transform transition-all duration-200 ${
                isClosing ? "opacity-0 scale-95" : "opacity-100 scale-100"
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="relative">
                <img
                  src={selectedLocation.locationBanner}
                  alt={selectedLocation.name}
                  className="w-full h-72 object-cover rounded-t-2xl"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent rounded-t-2xl" />
                <button
                  onClick={closePopup}
                  className="absolute top-4 right-4 p-2 bg-white rounded-full hover:bg-white/90 transition-colors duration-200 shadow-lg"
                >
                  <X className="w-5 h-5 text-[#1A4746]" />
                </button>
                <div className="absolute bottom-6 left-6 text-white">
                  <h3 className="text-3xl font-bold mb-2">
                    {selectedLocation.name}
                  </h3>
                  <div className="flex items-center text-white/90">
                    <MapPin className="w-4 h-4 mr-2" />
                    <p>{selectedLocation.town}, Sri Lanka</p>
                  </div>
                </div>
              </div>

              <div className="p-6 space-y-6">
                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-[#EAF4F4] p-4 rounded-xl">
                    <div className="flex items-center text-[#1A4746] mb-2">
                      <Navigation className="w-4 h-4 mr-2" />
                      <h4 className="font-medium">From Colombo Fort</h4>
                    </div>
                    <p className="text-[#427D7B] text-sm">
                      Distance: {selectedLocation.distanceColombo}
                    </p>
                    <p className="text-[#427D7B] text-sm">
                      Duration: {selectedLocation.timeColombo}
                    </p>
                  </div>

                  <div className="bg-[#EAF4F4] p-4 rounded-xl">
                    <div className="flex items-center text-[#1A4746] mb-2">
                      <Navigation className="w-4 h-4 mr-2" />
                      <h4 className="font-medium">From Airport</h4>
                    </div>
                    <p className="text-[#427D7B] text-sm">
                      Distance: {selectedLocation.distanceKatunayaka}
                    </p>
                    <p className="text-[#427D7B] text-sm">
                      Duration: {selectedLocation.timeKatunayaka}
                    </p>
                  </div>
                </div>

                <div>
                  <h4 className="text-lg font-semibold text-[#1A4746] mb-2">
                    About this destination
                  </h4>
                  <div className="h-32 overflow-y-auto pr-2 custom-scrollbar">
                    <p className="text-[#427D7B] leading-relaxed">
                      {selectedLocation.description}
                    </p>
                  </div>
                </div>

                <button
                  onClick={() => toggleLocationSelection(selectedLocation)}
                  className="w-full px-6 py-3 bg-[#F4A261] text-white rounded-xl hover:bg-[#F4B583] transition-all duration-300 transform hover:scale-[1.02] active:scale-[0.98] font-medium"
                >
                  {selectedLocations.some((loc) => loc.id === selectedLocation.id)
                    ? "Remove from Selection"
                    : "Add to Selection"}
                </button>
           

              </div>
            </div>
          </div>
        )}

        {/* No Results Message */}
        {filteredLocations.length === 0 && (
          <div className="text-center py-12">
            <div className="bg-white rounded-2xl shadow-lg p-8 max-w-md mx-auto">
              <Search className="w-12 h-12 text-[#9BBAB9] mx-auto mb-4" />
              <h3 className="text-xl font-semibold text-[#1A4746] mb-2">
                No destinations found
              </h3>
              <p className="text-[#427D7B]">
                Try adjusting your search terms or explore our featured
                destinations
              </p>
            </div>
          </div>
        )}

        {/* Mobile Selection Bar */}
        {selectedLocations.length > 0 && (
          <div className="fixed bottom-0 left-0 right-0 bg-white shadow-2xl md:hidden p-4 transform transition-transform z-40">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <span className="text-[#1A4746] font-medium">
                  {selectedLocations.length} selected
                </span>
                <div className="flex -space-x-2">
                  {selectedLocations.slice(0, 2).map((location) => (
                    <div
                      key={location.id}
                      className="w-6 h-6 rounded-full border-2 border-white overflow-hidden"
                    >
                      <img
                        src={location.locationBanner}
                        alt={location.name}
                        className="w-full h-full object-cover"
                      />
                    </div>
                  ))}
                  {selectedLocations.length > 2 && (
                    <div className="w-6 h-6 rounded-full border-2 border-white bg-[#1A4746] flex items-center justify-center text-white text-xs">
                      +{selectedLocations.length - 2}
                    </div>
                  )}
                </div>
              </div>
              <button
                onClick={handlePlanVisit}
                className="px-6 py-2 bg-[#F4A261] text-white rounded-lg hover:bg-[#F4B583] transition-all duration-300"
              >
                Plan Visit
              </button>
            </div>
          </div>
        )}
      </main>
    </>
  );
};

export default LocationView;
