import React, { useState, useEffect } from "react";
import { db } from "../../firebase/firebase";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";

const TourManagement = () => {
  const [tours, setTours] = useState([]);
  const [editingTour, setEditingTour] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    const fetchTours = async () => {
      const tourCollection = await getDocs(collection(db, "tours"));
      setTours(
        tourCollection.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    };
    fetchTours();
  }, []);

  const handleEdit = (tour) => {
    setEditingTour(tour);
    setShowEditModal(true);
  };

  const handleUpdateTour = async () => {
    const tourDoc = doc(db, "tours", editingTour.id);
    await updateDoc(tourDoc, editingTour);
    setTours((prevTours) =>
      prevTours.map((tour) => (tour.id === editingTour.id ? editingTour : tour))
    );
    setShowEditModal(false);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this tour?")) {
      await deleteDoc(doc(db, "tours", id));
      setTours((prevTours) => prevTours.filter((tour) => tour.id !== id));
    }
  };

  const handleAddField = (fieldName) => {
    if (fieldName === "dailyActivities") {
      setEditingTour({
        ...editingTour,
        dailyActivities: [
          ...editingTour.dailyActivities,
          { day: "", description: "" },
        ],
      });
    } else {
      setEditingTour({
        ...editingTour,
        [fieldName]: [...editingTour[fieldName], ""],
      });
    }
  };

  const handleRemoveField = (fieldName, index) => {
    setEditingTour({
      ...editingTour,
      [fieldName]: editingTour[fieldName].filter((_, idx) => idx !== index),
    });
  };
  return (
    <div className="min-h-screen bg-[#EAF4F4] p-6">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-[#1A4746] mb-8">
          Tour Management
        </h2>
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {tours.map((tour) => (
            <div
              key={tour.id}
              className="bg-white rounded-xl shadow-lg overflow-hidden transition-all hover:shadow-xl border border-[#D9E7E6]"
            >
              <div className="bg-[#215E5C] p-4">
                <h3 className="text-2xl font-bold text-[#EAF4F4]">
                  {tour.tourName}
                </h3>
              </div>
              <div className="p-6">
                <div className="space-y-4">
                  <div>
                    <p className="text-sm text-[#9BBAB9]">
                      Short Description (shortDescription)
                    </p>
                    <p className="text-[#2C3333]">{tour.shortDescription}</p>
                  </div>

                  <div className="flex gap-4">
                    <div>
                      <p className="text-sm text-[#9BBAB9]">
                        Nights (amountOfNights)
                      </p>
                      <p className="text-[#2C3333]">{tour.amountOfNights}</p>
                    </div>
                    <div>
                      <p className="text-sm text-[#9BBAB9]">
                        Days (amountOfDays)
                      </p>
                      <p className="text-[#2C3333]">{tour.amountOfDays}</p>
                    </div>
                  </div>

                  <div>
                    <p className="text-sm text-[#9BBAB9]">
                      Daily Activities (dailyActivities)
                    </p>
                    <div className="space-y-2 mt-2">
                      {tour.dailyActivities.map((activity, idx) => (
                        <div key={idx} className="bg-[#EAF4F4] p-3 rounded">
                          <p className="font-medium text-[#215E5C]">
                            {activity.day}
                          </p>
                          <p className="text-[#2C3333]">
                            {activity.description}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>

               
                  {["extras", "locations"].map((field) => (
                    <div key={field}>
                      <p className="text-sm text-[#9BBAB9]">
                        {field.charAt(0).toUpperCase() + field.slice(1)} (
                        {field})
                      </p>
                      <ul className="list-disc list-inside space-y-1 mt-2">
                        {tour[field].map((item, idx) => (
                          <li key={idx} className="text-[#2C3333]">
                            {item}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>

                <div className="mt-6 flex gap-3">
                  <button
                    onClick={() => handleEdit(tour)}
                    className="flex-1 bg-[#215E5C] text-[#EAF4F4] px-4 py-2 rounded-lg hover:bg-[#427D7B] transition-colors"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(tour.id)}
                    className="flex-1 bg-[#F4A261] text-[#2C3333] px-4 py-2 rounded-lg hover:bg-[#F4B583] transition-colors"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Edit Modal */}
        {showEditModal && (
          <div className="fixed inset-0 bg-[#2C3333] bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-xl shadow-xl w-full max-w-3xl max-h-[90vh] overflow-y-auto">
              <div className="sticky top-0 bg-[#215E5C] p-6">
                <h2 className="text-2xl font-bold text-[#EAF4F4]">
                  Edit Tour Details
                </h2>
              </div>

              <div className="p-6 space-y-6">
                <div>
                  <label className="block text-sm font-medium text-[#2C3333] mb-1">
                    Tour Name (tourName)
                  </label>
                  <input
                    type="text"
                    value={editingTour.tourName}
                    onChange={(e) =>
                      setEditingTour({
                        ...editingTour,
                        tourName: e.target.value,
                      })
                    }
                    className="w-full px-4 py-2 rounded-lg border border-[#D9E7E6] focus:ring-2 focus:ring-[#215E5C] focus:border-transparent"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-[#2C3333] mb-1">
                    Short Description (shortDescription)
                  </label>
                  <textarea
                    value={editingTour.shortDescription}
                    onChange={(e) =>
                      setEditingTour({
                        ...editingTour,
                        shortDescription: e.target.value,
                      })
                    }
                    className="w-full px-4 py-2 rounded-lg border border-[#D9E7E6] focus:ring-2 focus:ring-[#215E5C] focus:border-transparent"
                    rows="3"
                  />
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-[#2C3333] mb-1">
                      Nights (amountOfNights)
                    </label>
                    <input
                      type="number"
                      value={editingTour.amountOfNights}
                      onChange={(e) =>
                        setEditingTour({
                          ...editingTour,
                          amountOfNights: parseInt(e.target.value),
                        })
                      }
                      className="w-full px-4 py-2 rounded-lg border border-[#D9E7E6] focus:ring-2 focus:ring-[#215E5C] focus:border-transparent"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-[#2C3333] mb-1">
                      Days (amountOfDays)
                    </label>
                    <input
                      type="number"
                      value={editingTour.amountOfDays}
                      onChange={(e) =>
                        setEditingTour({
                          ...editingTour,
                          amountOfDays: parseInt(e.target.value),
                        })
                      }
                      className="w-full px-4 py-2 rounded-lg border border-[#D9E7E6] focus:ring-2 focus:ring-[#215E5C] focus:border-transparent"
                    />
                  </div>
                </div>

                <div>
                  <div className="flex justify-between items-center mb-2">
                    <h4 className="font-medium text-[#215E5C]">
                      Daily Activities (dailyActivities)
                    </h4>
                    <button
                      onClick={() => handleAddField("dailyActivities")}
                      className="px-3 py-1 bg-[#215E5C] text-[#EAF4F4] rounded-lg hover:bg-[#427D7B] transition-colors text-sm"
                    >
                      + Add Day
                    </button>
                  </div>
                  {editingTour.dailyActivities.map((activity, idx) => (
                    <div
                      key={idx}
                      className="grid grid-cols-4 gap-4 mb-4 relative"
                    >
                      <div className="col-span-1">
                        <label className="block text-sm font-medium text-[#2C3333] mb-1">
                          Day
                        </label>
                        <input
                          type="text"
                          value={activity.day}
                          onChange={(e) => {
                            const updatedActivities = [
                              ...editingTour.dailyActivities,
                            ];
                            updatedActivities[idx].day = e.target.value;
                            setEditingTour({
                              ...editingTour,
                              dailyActivities: updatedActivities,
                            });
                          }}
                          className="w-full px-4 py-2 rounded-lg border border-[#D9E7E6] focus:ring-2 focus:ring-[#215E5C] focus:border-transparent"
                        />
                      </div>
                      <div className="col-span-3">
                        <label className="block text-sm font-medium text-[#2C3333] mb-1">
                          Description
                        </label>
                        <div className="flex gap-2">
                          <input
                            type="text"
                            value={activity.description}
                            onChange={(e) => {
                              const updatedActivities = [
                                ...editingTour.dailyActivities,
                              ];
                              updatedActivities[idx].description =
                                e.target.value;
                              setEditingTour({
                                ...editingTour,
                                dailyActivities: updatedActivities,
                              });
                            }}
                            className="w-full px-4 py-2 rounded-lg border border-[#D9E7E6] focus:ring-2 focus:ring-[#215E5C] focus:border-transparent"
                          />
                          <button
                            onClick={() =>
                              handleRemoveField("dailyActivities", idx)
                            }
                            className="px-3 py-2 bg-[#F4A261] text-[#2C3333] rounded-lg hover:bg-[#F4B583] transition-colors"
                          >
                            ×
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Dynamic fields (locations, exclusions, inclusions, extras) */}
                {["extras", "locations"].map((field) => (
                  <div key={field}>
                    <div className="flex justify-between items-center mb-2">
                      <h4 className="font-medium text-[#215E5C]">
                        {field.charAt(0).toUpperCase() + field.slice(1)} (
                        {field})
                      </h4>
                      <button
                        onClick={() => handleAddField(field)}
                        className="px-3 py-1 bg-[#215E5C] text-[#EAF4F4] rounded-lg hover:bg-[#427D7B] transition-colors text-sm"
                      >
                        + Add {field.slice(0, -1)}
                      </button>
                    </div>
                    {editingTour[field].map((item, idx) => (
                      <div key={idx} className="mb-2">
                        <div className="flex gap-2">
                          <input
                            type="text"
                            value={item}
                            onChange={(e) => {
                              const updatedItems = [...editingTour[field]];
                              updatedItems[idx] = e.target.value;
                              setEditingTour({
                                ...editingTour,
                                [field]: updatedItems,
                              });
                            }}
                            className="w-full px-4 py-2 rounded-lg border border-[#D9E7E6] focus:ring-2 focus:ring-[#215E5C] focus:border-transparent"
                            placeholder={`Enter ${field.slice(0, -1)}`}
                          />
                          <button
                            onClick={() => handleRemoveField(field, idx)}
                            className="px-3 py-2 bg-[#F4A261] text-[#2C3333] rounded-lg hover:bg-[#F4B583] transition-colors"
                          >
                            ×
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>

              <div className="sticky bottom-0 bg-white p-6 border-t border-[#D9E7E6] flex justify-end gap-3">
                <button
                  onClick={() => setShowEditModal(false)}
                  className="px-6 py-2 bg-[#EAF4F4] text-[#2C3333] rounded-lg hover:bg-[#D9E7E6] transition-colors"
                >
                  Cancel
                </button>
                <button
                  onClick={handleUpdateTour}
                  className="px-6 py-2 bg-[#215E5C] text-[#EAF4F4] rounded-lg hover:bg-[#427D7B] transition-colors"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TourManagement;
