import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';
import { Link } from 'react-router-dom';
import { Search } from 'lucide-react';
import kithulgalaImage from '../../../assets/kithulgala.webp';
import Header from '../Header';

const colors = {
  primary: {
    dark: '#1A4746',    // Darker teal for headers
    main: '#215E5C',    // Main teal color
    light: '#427D7B',   // Lighter teal for hover states
  },
  secondary: {
    main: '#F4A261',    // Warm orange for accents
    light: '#F4B583',   // Lighter orange for hover states
  },
  neutral: {
    white: '#EAF4F4',   // Off-white for text on dark backgrounds
    light: '#D9E7E6',   // Light gray-teal for borders
    medium: '#9BBAB9',  // Medium gray-teal for inactive elements
    dark: '#2C3333',    // Dark gray for text
  }
};

const TripsView = () => {
  const [tours, setTours] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchTours = async () => {
      try {
        const toursCollection = collection(db, 'tours');
        const tourSnapshot = await getDocs(toursCollection);
        const tourList = tourSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setTours(tourList);
      } catch (error) {
        console.error("Error fetching tours:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTours();
  }, []);

  const filteredTours = tours.filter(tour =>
    tour.tourName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-neutral-white">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2" style={{ borderColor: colors.primary.main }}></div>
      </div>
    );
  }

  return (
    <>
    <Header />
    <div className="min-h-screen" style={{ backgroundColor: colors.neutral.white }}>
      {/* Hero Section */}
      <div className="relative h-64 mb-16 mt-16">
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{ backgroundImage: `url(${kithulgalaImage})` }}
        >
          <div className="absolute inset-0" style={{ backgroundColor: `${colors.primary.dark}99` }}></div>
        </div>
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <h1 className="text-4xl md:text-5xl font-bold mb-4" style={{ color: colors.neutral.white }}>
            Discover Adventures
          </h1>
          <p className="text-lg" style={{ color: colors.neutral.light }}>
            Explore our handpicked tours and experiences
          </p>
        </div>
      </div>

      {/* Search Section */}
      <div className="container mx-auto px-4 mb-12">
        <div className="relative max-w-md mx-auto">
          <Search className="absolute left-4 top-3.5 h-5 w-5" style={{ color: colors.neutral.medium }} />
          <input
            type="text"
            placeholder="Search tours..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full pl-12 pr-4 py-3 rounded-full shadow-sm focus:outline-none focus:ring-2"
            style={{ 
              backgroundColor: 'white',
              borderColor: colors.neutral.light,
              color: colors.neutral.dark,
              '--tw-ring-color': colors.primary.main
            }}
          />
        </div>
      </div>

      {/* Tours Grid */}
      <div className="container mx-auto px-4 pb-16">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredTours.map((tour) => (
            <Link 
              key={tour.id}
              to={`/tour/${encodeURIComponent(tour.tourName)}`}
              className="group"
            >
              <div className="bg-white rounded-xl overflow-hidden shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
                {/* Tour Image */}
                <div className="relative h-48 overflow-hidden">
                  <img 
                    src={tour.tourBannerImageUrl || kithulgalaImage} // Use tourBannerImageUrl or fallback to kithulgalaImage
                    alt={tour.tourName}
                    className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>
                </div>

                {/* Tour Content */}
                <div className="p-6">
                  <h2 
                    className="text-2xl font-bold mb-2 transition-colors"
                    style={{ 
                      color: colors.neutral.dark,
                      ':hover': { color: colors.primary.main }
                    }}
                  >
                    {tour.tourName}
                  </h2>
                  
                  <div className="flex justify-start space-x-6 mb-4">
                    <div className="text-center">
                      <span 
                        className="block text-lg font-semibold"
                        style={{ color: colors.primary.main }}
                      >
                        {tour.amountOfDays}
                      </span>
                      <span style={{ color: colors.neutral.medium }}>Days</span>
                    </div>
                    <div className="text-center">
                      <span 
                        className="block text-lg font-semibold"
                        style={{ color: colors.primary.main }}
                      >
                        {tour.amountOfNights}
                      </span>
                      <span style={{ color: colors.neutral.medium }}>Nights</span>
                    </div>
                  </div>

                  <p className="text-sm mb-6 line-clamp-2" style={{ color: colors.neutral.dark }}>
                    {tour.shortDescription}
                  </p>

                  <div className="flex items-center justify-between">
                    <span 
                      className="font-medium"
                      style={{ color: colors.secondary.main }}
                    >
                      View Details
                    </span>
                    <span 
                      className="w-8 h-8 rounded-full flex items-center justify-center transition-colors"
                      style={{ 
                        backgroundColor: colors.neutral.light,
                        ':hover': { backgroundColor: colors.primary.main }
                      }}
                    >
                      <svg 
                        className="w-4 h-4 transition-colors" 
                        style={{ color: colors.primary.main }}
                        fill="none" 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth="2" 
                        viewBox="0 0 24 24" 
                        stroke="currentColor"
                      >
                        <path d="M9 5l7 7-7 7" />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>

        {/* No Results Message */}
        {filteredTours.length === 0 && (
          <div className="text-center py-16">
            <h3 className="text-xl" style={{ color: colors.primary.main }}>
              No tours found matching your search.
            </h3>
            <p className="mt-2" style={{ color: colors.neutral.medium }}>
              Try adjusting your search terms.
            </p>
          </div>
        )}
      </div>
    </div>
    </>
  );
};

export default TripsView;
