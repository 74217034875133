import React from "react";
import placeholderImg from "../../../assets/background.jpg";
import HeaderAboutUS from "../Header";

const AboutUs = () => {
  return (
    <>
      <HeaderAboutUS />
      <main className="min-h-screen bg-[#EAF4F4]">
        {/* Hero Section */}
        <section className="relative h-[60vh] sm:h-[75vh] md:h-[90vh] flex items-center justify-center overflow-hidden">
          {/* Background Image with Overlay */}
          <div className="absolute inset-0">
            <img
              src={placeholderImg}
              alt="Sri Lankan landscape"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-b from-[#1A4746]/90 to-[#1A4746]/70" />
          </div>

          {/* Hero Content */}
          <div className="relative z-10 text-center px-4 sm:px-6">
            <h1 className="text-4xl sm:text-6xl md:text-7xl lg:text-8xl font-bold text-[#EAF4F4] mb-4 sm:mb-8 tracking-wide">
              AYUBOWAN
            </h1>
            <div className="text-lg sm:text-xl md:text-2xl lg:text-3xl text-[#D9E7E6] font-light max-w-3xl mx-auto">
              Welcome to
              <span className="font-medium"> Rajarata Tropical Ceylon Tours</span>
            </div>
          </div>
        </section>

        {/* Mission & Vision Section */}
        <section className="py-12 sm:py-16 md:py-24 px-4 sm:px-6 bg-white">
          <div className="max-w-7xl mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 md:gap-16">
              {/* Vision */}
              <div className="space-y-6 sm:space-y-8">
                <div className="flex items-center space-x-4">
                  <div className="w-10 h-10 sm:w-12 sm:h-12 bg-[#215E5C] rounded-full flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 sm:h-6 sm:w-6 text-[#EAF4F4]"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                      />
                    </svg>
                  </div>
                  <h2 className="text-2xl sm:text-3xl font-bold text-[#1A4746]">
                    Our Vision
                  </h2>
                </div>
                <div className="pl-6 sm:pl-16">
                  <p className="text-base sm:text-lg text-[#2C3333] leading-relaxed">
                    To be the premier global gateway that inspires, informs, and
                    immerses travelers in the captivating beauty and cultural
                    richness of Sri Lanka, delivering a seamless and
                    personalized experience for every traveler.
                  </p>
                </div>
              </div>

              {/* Mission */}
              <div className="space-y-6 sm:space-y-8">
                <div className="flex items-center space-x-4">
                  <div className="w-10 h-10 sm:w-12 sm:h-12 bg-[#215E5C] rounded-full flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 sm:h-6 sm:w-6 text-[#EAF4F4]"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                      />
                    </svg>
                  </div>
                  <h2 className="text-2xl sm:text-3xl font-bold text-[#1A4746]">
                    Our Mission
                  </h2>
                </div>
                <div className="pl-6 sm:pl-16">
                  <p className="text-base sm:text-lg text-[#2C3333] leading-relaxed">
                    To provide tourists with immersive, customized tours that
                    highlight the island's rich cultural legacy, breathtaking
                    natural scenery, and genuine local experiences in order to
                    inspire and facilitate journeys to Sri Lanka that will never
                    be forgotten. In addition to encouraging sustainable
                    practices that protect Sri Lanka's natural beauty and help
                    local populations, our goal is to introduce tourists to the
                    country's genuine essence.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Our Story Section */}
        <section className="py-12 sm:py-16 md:py-24 px-4 sm:px-6 bg-[#EAF4F4]">
          <div className="max-w-7xl mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 md:gap-16 items-center">
              {/* Story Content */}
              <div className="space-y-6 sm:space-y-8">
                <div className="flex items-center space-x-4">
                  <div className="w-10 h-10 sm:w-12 sm:h-12 bg-[#F4A261] rounded-full flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 sm:h-6 sm:w-6 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                      />
                    </svg>
                  </div>
                  <h2 className="text-2xl sm:text-3xl font-bold text-[#1A4746]">
                    Our Story
                  </h2>
                </div>
                <div className="pl-6 sm:pl-16 space-y-4 sm:space-y-6">
                  <p className="text-base sm:text-lg text-[#2C3333] leading-relaxed">
                    Rajarata Tropical Ceylon Tours (Private) Limited is a
                    start-up company in the tourism industry yet its highly
                    confident in gaining excellence in the sector in a short
                    time mainly leveraging on the expertise of the team it has
                    engaged in this journey.
                  </p>
                  <p className="text-base sm:text-lg text-[#2C3333] leading-relaxed">
                    We are a duly incorporated company under the Companies Act
                    No. 7 of 2007 in Sri Lanka, holding registration number PV
                    00311497.
                  </p>
                </div>
              </div>

              {/* Image Grid */}
              <div className="grid grid-cols-2 gap-3 sm:gap-4 mt-8 lg:mt-0">
                <div className="space-y-3 sm:space-y-4">
                  <div className="aspect-square rounded-xl sm:rounded-2xl overflow-hidden shadow-lg">
                    <img
                      src={placeholderImg}
                      alt="Sri Lankan culture"
                      className="w-full h-full object-cover hover:scale-110 transition-transform duration-500"
                    />
                  </div>
                  <div className="aspect-square rounded-xl sm:rounded-2xl overflow-hidden shadow-lg">
                    <img
                      src={placeholderImg}
                      alt="Local experiences"
                      className="w-full h-full object-cover hover:scale-110 transition-transform duration-500"
                    />
                  </div>
                </div>
                <div className="mt-4 sm:mt-8">
                  <div className="aspect-[3/4] rounded-xl sm:rounded-2xl overflow-hidden shadow-lg">
                    <img
                      src={placeholderImg}
                      alt="Natural landscapes"
                      className="w-full h-full object-cover hover:scale-110 transition-transform duration-500"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default AboutUs;