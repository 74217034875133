import React, { useState } from "react";
import Tours from "./Tours";
import Locations from "./Locations";
import TourManagement from "./TourManagement";

const AdminPanel = () => {
  const [activeSection, setActiveSection] = useState("Tours");

  return (
    <div className="min-h-screen flex" style={{ backgroundColor: "#D9E7E6" }}>
      {/* Sidebar */}
      <aside className="w-1/4 p-8" style={{ backgroundColor: "#215E5C" }}>
        <h2 className="text-3xl font-bold mb-6" style={{ color: "#EAF4F4" }}>
          Admin Dashboard
        </h2>
        <nav>
          <button
            onClick={() => setActiveSection("Tours")}
            className={`block w-full py-2 px-4 text-left rounded mb-2 ${
              activeSection === "Tours"
                ? "bg-[#1A4746] text-[#EAF4F4]"
                : "hover:bg-[#1A4746] text-[#EAF4F4]"
            }`}
          >
            Tours
          </button>
          <button
            onClick={() => setActiveSection("Locations")}
            className={`block w-full py-2 px-4 text-left rounded ${
              activeSection === "Locations"
                ? "bg-[#1A4746] text-[#EAF4F4]"
                : "hover:bg-[#1A4746] text-[#EAF4F4]"
            }`}
          >
            Locations
          </button>
          <button
            onClick={() => setActiveSection("Tour View")}
            className={`block w-full py-2 px-4 text-left rounded mb-2 ${
              activeSection === "Tour View"
                ? "bg-[#1A4746] text-[#EAF4F4]"
                : "hover:bg-[#1A4746] text-[#EAF4F4]"
            }`}
          >
            Tours
          </button>
        </nav>
      </aside>

      {/* Main content */}
      <main className="flex-1 p-8">
        {activeSection === "Tours" && <Tours />}
        {activeSection === "Locations" && <Locations />}
        {activeSection === "Tour View" && <TourManagement />}
      </main>
    </div>
  );
};

export default AdminPanel;
